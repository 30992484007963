import React from 'react';

import VideoPage from './Components/VideoPage/VideoPage';
import NotFound from './Components/NotFound/NotFound';

import videoTrees from './videoTrees';

// Default routes
let routes = [
	{
		path: '/',
		exact: true,
		title: videoTrees[0].title,
		name: videoTrees[0].name,
		element: <VideoPage videoTree={videoTrees[0]} />
	},
	{
		path: '*',
		title: 'Not found',
		name: 'NotFound',
		element: <NotFound />
	}
];

// Add one route for each video tour
const videoTreesWithRenderElement = videoTrees.map((videoTree) => {
	return {
		...videoTree,
		path: videoTree.urlPath,
		element: <VideoPage videoTree={videoTree} />
	};
});

// Merge the two routes
routes = [...routes, ...videoTreesWithRenderElement];

export default routes;