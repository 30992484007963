import ImgRoros from './images/roros.jpg';
import ImgOstersund from './images/ostersund.jpg';
import ImgOppdal from './images/oppdal.jpg';

import VideoMapTrondheimOstersund from './video_map_trondheim_and_ostersund.json';
import VideoMapRorosFunasdalen from './video_map_roros_and_funasdalen.json';
import VideoMapOppdal from './video_map_oppdal.json';

const videoTrees = [
	{
		title: 'Trondheim and Östersund',
		name: 'WelcomeTrondheimAndOstersund',
		description: '',
		image: ImgOstersund,
		videoMap: VideoMapTrondheimOstersund,
		urlPath: '/trondheim-and-ostersund',
	},
	{
		title: 'Røros and Funäsdalen',
		name: 'RorosAndFunasdalen',
		description: '',
		image: ImgRoros,
		videoMap: VideoMapRorosFunasdalen,
		urlPath: '/roros-and-funasdalen',
	},
	{
		title: 'Oppdal',
		name: 'Oppdal',
		description: '',
		image: ImgOppdal,
		videoMap: VideoMapOppdal,
		urlPath: '/oppdal',
	}
];

export default videoTrees;