import React from 'react';
import ChooseVideoButtons from '../../ChooseVideoButtons/ChooseVideoButtons';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import videojs from 'video.js';
import './vjsChooseVideoButtons.scss';

const vjsComponent = videojs.getComponent('Component');
class vjsChooseVideoButtons extends vjsComponent {

  constructor(player, options) {
    super(player, options);

    this.addClass('vjsChooseVideoButtons');

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on("dispose", () => {
      ReactDOM.unmountComponentAtNode(this.el())
    });
  }

  /**
   * We will render out the React ChooseVideoButtons component into the DOM element
   * generated automatically by the VideoJS createEl() method.
   *
   * We fetch that generated element using `this.el()`, a method provided by the
   * vjsComponent class that this class is extending.
   */
  mount() {
    const container = this.el();
    const videoButtonsRoot = createRoot(container);
    videoButtonsRoot.render(<ChooseVideoButtons vjsComponent={this} />);
  }
}

vjsComponent.registerComponent('vjsChooseVideoButtons', vjsChooseVideoButtons);

export default vjsChooseVideoButtons;