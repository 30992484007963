import React from 'react';
import './NotFound.scss';

const NotFound = (props) => {
	return (
		<div className="NotFound">
			<h1>Not found</h1>
		</div>
	);
};

export default NotFound;