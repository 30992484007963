import React, { useState, useEffect, useRef } from 'react';
import { 
  Container, Col, Row, 
  Card, CardImg, CardTitle, CardText, CardDeck, CardBody
} from "reactstrap";

import VideoJS from '../VideoJS/VideoJS.jsx' // point to where the functional component is stored

//eslint-disable-next-line
import vjsChooseVideoButtons from '../VideoJS/vjsChooseVideoButtons/vjsChooseVideoButtons.js'; // warning ignored because it is used by the videoJsOptions object

import videoTrees from '../../videoTrees';

import LogoInterReg from '../../images/logo_interreg_white2.png';
import LogoJHT from '../../images/logo_jht_white.png';
import LogoRJH from '../../images/logo_rjh_white.png';
import LogoExploreTrondelag from '../../images/logo_explore_trondelag_white.png';
import LogoTrondelag from '../../images/logo_trondelag_white.png';

const VideoPage = (props) => {
	const playerRef = useRef(null);

	const [videos, setVideos] = useState([]);

	const [currentVideoTree, setCurrentVideoTree] = useState(videoTrees[0]);

	const [showVideoButtons, setShowVideoButtons] = useState(false);
	const [videoButtons, setVideoButtons] = useState([]);
	const [showCallToActionButtons, setShowCallToActionButtons] = useState(false);
	const [callToActionButtons, setCallToActionButtons] = useState({}); // @todo: change to array in future to allow for secondary buttons aswell
	const [loopTime, setLoopTime] = useState(-1); // set to -1 when the video shouldn't loop

	const [currentVideo, setCurrentVideo] = useState({});

	const [isPlayerReady, setIsPlayerReady] = useState(false);

	const [videoJsOptions, setVideoJsOptions] = useState({
		autoplay: true,
		controls: true,
		responsive: true,
		fluid: true,
		preload: 'auto',
		sources: [
			{
			src: 'https://videodelivery.net/' + currentVideoTree.videoMap.videos[0].videoId + '/manifest/video.m3u8',
			type: 'application/x-mpegURL'
			},
			{
			src: 'https://videodelivery.net/' + currentVideoTree.videoMap.videos[0].videoId + '/manifest/video.mpd',
			type: 'application/x-mpegURL'
			}
		],
		vjsChooseVideoButtons: {
			videoButtons: videoButtons,
		}
	});

	const [videoHasEnded, setVideoHasEnded] = useState(false);
	const [videoHasStartedPlaying, setVideoHasStartedPlaying] = useState(false);

	const handlePlayerReady = (player) => {
		playerRef.current = player;

		player.getChild('ControlBar').removeChild('ProgressControl');
		player.getChild('ControlBar').removeChild('pictureInPictureToggle');
		//player.getChild('ControlBar').removeChild('CaptionsButton');

		if(isIOS()){
			player.getChild('ControlBar').removeChild('fullscreenToggle');
		}

		player.on('ready', () => {
			//console.log('player is ready');
			setIsPlayerReady(true);
			/*
			player.autoplay(true);
			if(videos !== undefined && videos !== null && videos.length >= 1){
			changePlayerOptions(videos[0]);
			}
			*/
		});

		player.on('play', () => {
			//console.log('started playing');
			
			// Get all text tracks for the current player.
			var tracks = player.textTracks();

			for (var i = 0; i < tracks.length; i++) {
				var track = tracks[i];

				// Find the English captions track and mark it as "showing".
				if (track.kind === 'subtitles' && track.language === 'en') {
					track.mode = 'showing';
				}
			}

			setVideoHasEnded(false);
			setVideoHasStartedPlaying(true);
		});

		player.on('ended', () => {
			setVideoHasEnded(true);
		});
	};

	const handleChangeVideo = (event) => {
		//console.log('app.js: handleChangeVideo: video');
		//console.log(event.detail.video);
		setVideoHasEnded(false); // temporary hack to prevent selecting of wrong video after the previous has stopped playing
		changePlayerOptions(event.detail.video, event.detail.skipToLoopTimer);
	}

	const changePlayerOptions = ( video, skipToLoopTimer = false ) => {
		//console.log('app.js: changePlayerOptions: video');
		//console.log(video);
		//console.log(skipToLoopTimer);
		//you can update the player through the Video.js player instance
		if (!playerRef.current || video === undefined || video.videoId === undefined) {
			return;
		}

		setVideoHasStartedPlaying(false);
		setShowVideoButtons(false);
		setShowCallToActionButtons(false);

		if(video.videos !== undefined && video.videos.length >= 1){
			setVideoButtons(video.videos.map((video) => {
				return video;
			}));
		}
		else{
			setVideoButtons([]);
		}

		if(video.callToAction !== undefined && video.callToAction.url !== undefined && video.callToAction.url !== '' && video.callToAction.url !== null ){
			setCallToActionButtons(video.callToAction);
		}
		else{
			setCallToActionButtons({});
		}

		if(video.loopTime !== undefined){
			setLoopTime(parseFloat(video.loopTime));
		}
		else{
			setLoopTime(-1);
		}

		setCurrentVideo(video);

		let startTime = 0;
		if(skipToLoopTimer && video.loopTime !== undefined && video.loopTime >= 2){
			startTime = video.loopTime; //we remove 1 sek from loop timer, so we start the video a little earlier we see the buttons fade in
		}

		setVideoJsOptions((prevState) => {
			return {
				...prevState,
				sources: [
					{
						src: 'https://videodelivery.net/' + video.videoId + '/manifest/video.m3u8',
						type: 'application/x-mpegURL'
					},
					{
						src: 'https://videodelivery.net/' + video.videoId + '/manifest/video.mpd',
						type: 'application/x-mpegURL'
					}
				],
				startTime: startTime
			}
		});
	};

	const isIOS = () => {
		const iOSVariants = [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		];

		return iOSVariants.includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
	}

	const handleVideoTreeChange = (videoTree) => {
		setCurrentVideoTree(videoTree);
	}

	useEffect(() => {
		// start first video if options has now source. State options will get source only when a video is selected
		if(videos !== undefined && videos !== null && videos.length >= 1){
			changePlayerOptions(videos[0]);
		}

		const videosUpdatedEvent = new CustomEvent( 'videosUpdated', { detail: { videos: videos } } );
		window.dispatchEvent(videosUpdatedEvent);
	}, [videos, isPlayerReady]);

	useEffect(() => {
		if(!playerRef.current){
			return;
		}

		playerRef.current.on('timeupdate', () => {
			//console.log(player.currentTime());
			if(showVideoButtons === false && loopTime >= 0 && playerRef.current.currentTime() >= loopTime){
				setShowVideoButtons(true);
			}

			if(showCallToActionButtons === false && playerRef.current.currentTime() >= 0.1 && playerRef.current.currentTime() >= (playerRef.current.duration() - 5)){
				setShowCallToActionButtons(true);
			}
		});
	}, [loopTime, showVideoButtons, isPlayerReady]);

	useEffect(() => {
		//console.log('videoButtons updated in App.js');
		const videoButtonsEvent = new CustomEvent( 'updateVideoButtons', { detail: { videoButtons: videoButtons } } );
		window.dispatchEvent(videoButtonsEvent);
	}, [videoButtons, isPlayerReady]);

	useEffect(() => {
		//console.log('callToActionButtons updated in App.js');
		const callToActionButtonsEvent = new CustomEvent( 'setCallToActionButtons', { detail: { callToActionButtons: callToActionButtons } } );
		window.dispatchEvent(callToActionButtonsEvent);
	}, [callToActionButtons, isPlayerReady]);

	useEffect(() => {
		//console.log('showVideoButtons updated in App.js');
		const showVideoButtonsEvent = new CustomEvent( 'showVideoButtons', { detail: { show: showVideoButtons } } );
		window.dispatchEvent(showVideoButtonsEvent);
	}, [showVideoButtons]);

	useEffect(() => {
		//console.log('showCallToActionButtons updated in App.js: ' + showCallToActionButtons);
		const showCallToActionButtonsEvent = new CustomEvent( 'showCallToActionButtons', { detail: { show: showCallToActionButtons } } );
		window.dispatchEvent(showCallToActionButtonsEvent);
	}, [showCallToActionButtons]);

	useEffect(() => {
		//console.log('currentVideo updated in App.js');
		//console.log(currentVideo);
		const setCurrentVideoEvent = new CustomEvent( 'setCurrentVideo', { detail: { currentVideo: currentVideo } } );
		window.dispatchEvent(setCurrentVideoEvent);
	}, [currentVideo, isPlayerReady]);

	useEffect(() => {
		//console.log('videoHasStartedPlaying updated in App.js');
		//console.log(videoHasStartedPlaying);

		if(videoHasStartedPlaying){
			const setVideoHasStartedPlayingEvent = new CustomEvent( 'setVideoHasStartedPlaying', { detail: { videoHasStartedPlaying: true } } );
			window.dispatchEvent(setVideoHasStartedPlayingEvent);
		}
	}, [videoHasStartedPlaying]);

	useEffect(() => {
		if(videoButtons !== undefined 
			&& videoButtons !== null 
			&& videoButtons !== '' 
			&& Array.isArray(videoButtons) 
			&& videoButtons.length >= 1
			&& videoHasEnded
		){
			//console.log('autoplay trigger');
			setVideoHasEnded(false);
			changePlayerOptions(videoButtons[0]);
		}
	}, [videoButtons, videoHasEnded]);

	useEffect(() => {
		console.log('changing video tree and set videos to:');
		console.log(currentVideoTree.videoMap);
		setVideos(currentVideoTree.videoMap.videos);
	},[currentVideoTree]);

	useEffect(() => {
		if(currentVideoTree.videoMap !== undefined && currentVideoTree.videoMap.videos !== undefined){
			setVideos(currentVideoTree.videoMap.videos);
		}

		window.addEventListener('changeVideo', handleChangeVideo);

		return () => {
			window.removeEventListener('changeVideo', handleChangeVideo);
		}
	}, []);

	useEffect(() => {
		if(videoJsOptions !== null 
			&& videoJsOptions !== undefined 
			&& videoJsOptions !== '' 
			&& videoJsOptions.startTime !== undefined 
			&& videoJsOptions.startTime !== null 
			&& videoJsOptions.startTime !== ''
			&& playerRef !== undefined
			&& playerRef !== null
			&& playerRef !== ''
			&& playerRef.current !== undefined
			&& playerRef.current !== null
			&& playerRef.current !== ''
		){
			playerRef.current.currentTime(videoJsOptions.startTime);
		}
	}, [videoJsOptions]);

	useEffect(() => {
		if(props.videoTree !== undefined && props.videoTree !== null && props.videoTree !== ''){
			console.log('set current video tree');
			console.log(props.videoTree);
			setCurrentVideoTree(props.videoTree);
		}
	}, [props.videoTree]);

	return (
		<div className="VideoPage">
			<div className="bithero-videojs">
				<div className="main-destination">
					<Container className="video-container">
						<Row>
							<Col sm="12" md="12" className="align-self-center main-destination-text">
								<h1>{ currentVideoTree.videoMap.pageTitle }</h1>
							</Col>
							<Col sm="12" md="12" className="">
								<VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
							</Col>
						</Row>
						{ currentVideoTree.videoMap.description !== undefined && currentVideoTree.videoMap.description !== '' && (
							<Row>
								<Col>
									{ currentVideoTree.videoMap.description }
								</Col>
							</Row>
						)}
					</Container>
				</div>
				<div className="other-destinations">
				<Container className="destinations-container">
					<Row>
					<Col sm="12" md="12" className="align-self-center">
						<h2>Other videos</h2>
						<CardDeck>
							{videoTrees.filter((videoTree) => {
								return JSON.stringify(videoTree) !== JSON.stringify(currentVideoTree);
							}).map((videoTree, index) => {
								return (
									<Card key={index}>
										<CardImg top width="100%" height="auto" src={videoTree.image} alt="" />
										<CardBody>
											<CardTitle tag="h5">{videoTree.title}</CardTitle>
											<CardText>{videoTree.description}</CardText>
											<a className="btn btn-green" href={videoTree.urlPath}>Watch now</a>
										</CardBody>
									</Card>
								);
							})}
						</CardDeck>
					</Col>
					</Row>
				</Container>
				</div>
			</div>

			<div id="footer">
				<Container>
					<Row>
						<Col sm="12" className="partner-logos">
							<img src={LogoRJH} height="80" alt="Logo Region Jämtland Härjedalen" /> 
							<img src={LogoTrondelag} height="80" alt="Logo Trøndelag fylkeskommune" /> 
							<img src={LogoJHT} height="80" alt="Logo Jämtland Härjedalen Turism" /> 
							<img src={LogoExploreTrondelag} height="80" alt="Logo Explore Trøndelag" /> 
							<img src={LogoInterReg} height="80" alt="Logo Interreg Sverige - Norge" />
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default VideoPage;