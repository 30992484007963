import React from "react";
import { 
  Routes,
  Route
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import routes from './routes';
import VideoPage from './Components/VideoPage/VideoPage';

/* 
  Convert SRT caption files to WebVTT files that can be uploaded to Cloudflare here: https://subtitletools.com/convert-to-vtt-online 
  WebVTT docs: https://developer.mozilla.org/en-US/docs/Web/API/WebVTT_API#webvtt_comments
*/

const App = () => {
  return (
    <div className="App">
      <Routes>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={route.element}
            />
          );
        })}
      </Routes>
    </div>
  );
}

export default App;
