import React, { useState, useEffect } from 'react';
import './ChooseVideoButtons.scss';

import FlagNorway from '../../images/flag_norway.png';
import FlagSweden from '../../images/flag_sweden.png';

const ChooseVideoButtons = (props) => {
	const [videos, setVideos] = useState([]);

	const [showVideoButtons, setShowVideoButtons] = useState(false);
	const [videoButtons, setVideoButtons] = useState([]);
	const [callToActionButtons, setCallToActionButtons] = useState({});
	const [showCallToActionButtons, setShowCallToActionButtons] = useState(false);

	const [showHeaderNav, setShowHeaderNav] = useState(false);

	const [currentVideo, setCurrentVideo] = useState({});
	const [prevVideo, setPrevVideo] = useState(null);

	const [videoHasStartedPlaying, setVideoHasStartedPlaying] = useState(false);

	const handleButtonClick = (video, skipToLoopTimer = false) => {
		setShowHeaderNav(false);
		setShowCallToActionButtons(false);
		setVideoHasStartedPlaying(false);

		const changeVideoEvent = new CustomEvent( 'changeVideo', { detail: { video: video, skipToLoopTimer: skipToLoopTimer } } );
    	window.dispatchEvent(changeVideoEvent);

    	if(video.videos !== undefined && Array.isArray(video.videos) && video.videos.length >= 1){
    		setPrevVideo(video);
    	}
	}

	const handleVideosUpdated = (event) => {
		setVideos(event.detail.videos);
	}

	const handleVideoButtonsUpdate = (event) => {
		setVideoButtons(event.detail.videoButtons);
	}

	const handleShowVideoButtons = (event) => {
		setShowVideoButtons(event.detail.show);
	}

	const handleCurrentVideoUpdated = (event) => {
		//console.log('handleCurrentVideoUpdated');
		//console.log(event.detail);
		setCurrentVideo(event.detail.currentVideo);
	}

	const handlePreviousVideoMenuoUpdated = (video) => {
		setPrevVideo(video);
	}

	const handleCallToActionButtonsUpdated = (event) => {
		//console.log('call to action event handler');
		//console.log(event.detail);
		setCallToActionButtons(event.detail.callToActionButtons);
	}

	const handleShowCallToActionButtons = (event) => {
		//console.log('showCallToActionButtons updated in ChooseVideoButtons.js');
		//console.log(event.detail.show);

		setShowCallToActionButtons(event.detail.show);
	}

	const handleVideoHasStartedPlaying = (event) => {
		//console.log('showCallToActionButtons updated in ChooseVideoButtons.js');
		//console.log(event.detail.show);

		setVideoHasStartedPlaying(event.detail.videoHasStartedPlaying);
	}

	useEffect(() => {
		window.addEventListener('updateVideoButtons', handleVideoButtonsUpdate);
		window.addEventListener('showVideoButtons', handleShowVideoButtons);
		window.addEventListener('videosUpdated', handleVideosUpdated);
		window.addEventListener('setCurrentVideo', handleCurrentVideoUpdated);
		window.addEventListener('setPreviousVideoMenu', handlePreviousVideoMenuoUpdated);
		window.addEventListener('setCallToActionButtons', handleCallToActionButtonsUpdated);
		window.addEventListener('showCallToActionButtons', handleShowCallToActionButtons);
		window.addEventListener('setVideoHasStartedPlaying', handleVideoHasStartedPlaying);

		return () => {
			window.removeEventListener('updateVideoButtons', handleVideoButtonsUpdate);
			window.removeEventListener('showVideoButtons', handleShowVideoButtons);
			window.removeEventListener('videosUpdated', handleVideosUpdated);
			window.removeEventListener('setCurrentVideo', handleCurrentVideoUpdated);
			window.removeEventListener('setPreviousVideoMenu', handlePreviousVideoMenuoUpdated);
			window.removeEventListener('setCallToActionButtons', handleCallToActionButtonsUpdated);
			window.removeEventListener('showCallToActionButtons', handleShowCallToActionButtons);
			window.removeEventListener('setVideoHasStartedPlaying', handleVideoHasStartedPlaying);
		}
	}, []);

	useEffect(() => {
		//console.log(videoButtons);
	}, [videoButtons]);

	useEffect(() => {
		//console.log('videos set within ChooseVideoButtons');
		//console.log(videos);
	}, [videos]);

	useEffect(() => {
		//console.log('currentVideo in ChooseVideoButtons');
		//console.log(currentVideo);

		const showHeaderNavDelay = setTimeout(() => {
			//console.log(currentVideo);

			setShowHeaderNav(true);
		}, 1000);

		return () => {
			clearTimeout(showHeaderNavDelay);
		}
	}, [currentVideo]);

	useEffect(() => {
		//console.log('prevVideo in ChooseVideoButtons');
		//console.log(prevVideo);
	}, [prevVideo]);

	useEffect(() => {
		//console.log('showHeaderNav: ' + showHeaderNav);
	}, [showHeaderNav]);

	useEffect(() => {
		//console.log('showCallToActionButtons is changed to: ' + showCallToActionButtons);
	}, [showCallToActionButtons]);

	return (
		<div className="ChooseVideoButtons">
			<div className={ `headerNav ${(showHeaderNav && videoHasStartedPlaying) ? "cyoav-show" : ""}` }>
				{ prevVideo !== null && (videoButtons.length <= 0) && (
					<button onClick={() => handleButtonClick(prevVideo, true)}>{ !showVideoButtons && videoButtons.length >= 1 ? "Skip" : "Back" } to { prevVideo.title.toLowerCase() } menu</button>
				)}
				<button onClick={() => handleButtonClick(videos[0], true)}>{ currentVideo === videos[0] ? "Skip to menu" : "Back to main menu" }</button>
			</div>

			<div className={ `videoButtons ${(videoButtons !== undefined && Array.isArray(videoButtons) && videoButtons.length <= 3) ? "treeOrLessChildren" : "" } ${(showVideoButtons) ? "cyoav-show" : ""}` }>
				{showVideoButtons && videoButtons !== undefined && videoButtons.length >= 1 && videoButtons.filter((videoButton) => {
					return videoButton.videoId !== '' && videoButton.title !== '';
				}).map((videoButton) => {
					return (
						<div key={videoButton.videoId} className="buttonWrapper">
							<button className={ `${(videoButton.buttonTextAlignment === "center") ? "buttonTextAlignCenter" : "" }` } onClick={() => handleButtonClick(videoButton)}> { videoButton.country !== undefined && (videoButton.country === 'no' || videoButton.country === 'se') && (<img src={ videoButton.country === 'no' ? FlagNorway : FlagSweden } alt="" />) } <span>{videoButton.title}</span></button>
						</div>
					);
				})}
			</div>

			<div className={ `callToAction ${(showCallToActionButtons) ? "cyoav-show" : ""}` }>
				{callToActionButtons.url !== undefined && callToActionButtons.url !== '' && (
					<div className="buttonWrapper">
						<a href={callToActionButtons.url} target="_blank" className="button" rel="noopener noreferrer"><span>{callToActionButtons.title !== undefined ? callToActionButtons.title : 'Read more' }</span></a>
					</div>
				)}
			</div>
		</div>
	);
}

export default ChooseVideoButtons;